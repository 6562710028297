import React from 'react'
import store from 'store'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useLoading, useDialog } from '../index'
import http from '../../service/http'
import myPackage from '../../../package.json'

const ITEM_HEIGHT = 48

export default ({ classes, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage] = useDialog({})
  const open = Boolean(anchorEl)
  const user = store.get('user')

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleLogout = async () => {
    const result = await withLoading(() => http.delete('auth/logout'))
    if (result.status !== 'success') {
      return showWithMessage(result.message)
    }
    store.remove('user')
    store.remove('token')
    return history.push('/login')
  }

  return (
    <div className={classes.menu}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        className={classes.iconMenu}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: 200,
          },
        }}
      >
        {user && user.name && (
          <MenuItem>
            ຊື່ຜູ້ໃຊ້: {user.name}{' '}
            {user.warehouse_name ? `(ສາງ: ${user.warehouse_name})` : '(Admin)'}
          </MenuItem>
        )}
        <MenuItem>Version {myPackage.version}</MenuItem>
        <MenuItem onClick={handleLogout}>ອອກ​ຈາກ​ລະ​ບົບ</MenuItem>
      </Menu>
      <Loading />
      <Dialog />
    </div>
  )
}
