import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { map } from 'lodash'
import store from 'store'
import queryString from 'query-string'
import TableHistory from '../../components/tableHistory'
import { useLoading, useDialog } from '../../components'
import http from '../../service/http'
import Modal from '../../components/editChina'

const headCells = [
  { id: 'index', numeric: false, disablePadding: true, label: 'ລຳດັບ' },
  { id: 'updatedAt', numeric: true, disablePadding: false, label: 'ວັນທີຮັບເຄື່ອງ' },
  { id: 'updatedAt', numeric: true, disablePadding: false, label: 'ເວລາ' },
  { id: 'user_tel', numeric: true, disablePadding: false, label: 'ເບີໂທ' },
  { id: 'user_name', numeric: true, disablePadding: false, label: 'ຊື່' },
  { id: 'code', numeric: true, disablePadding: false, label: 'ລະຫັດ' },
  { id: 'weight', numeric: true, disablePadding: false, label: 'ນ້ຳໜັກ' },
  { id: 'volume', numeric: true, disablePadding: false, label: 'ບໍລິມາດ' },
  { id: 'cost', numeric: true, disablePadding: false, label: 'ລາຄາ (KIP)' },
  { id: 'warehouse_name', numeric: true, disablePadding: false, label: 'ສາງ' },
]

export default () => {
  const userStore = store.get('user')
  const history = useHistory()

  const [rows, setRows] = useState([])
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({})
  const [show, setShow] = useState(false)
  const [total, setTotal] = useState(0)
  const [selected, setSelected] = useState([])
  const [sum, setSum] = useState([])
  const [bath, setBath] = useState([])

  const getListProduct = async (argOffset, argLimit, argSort, argSearch, argFilter) => {
    try {
      const { search } = history.location
      const queryPath = queryString.parse(search)
      const from = argFilter && argFilter.from && new Date(argFilter.from)
      const to = argFilter && argFilter.to && new Date(argFilter.to)
      const timezone = 7

      from && from.setTime(from.getTime() - timezone * 60 * 60 * 1000)
      to && to.setTime(to.getTime() - timezone * 60 * 60 * 1000)
      const payload = {
        offset: argOffset || queryPath.offset || 0,
        limit: argLimit || queryPath.limit || 25,
        search: argSearch,
        sortBy: (argSort && argSort.sortBy) || queryPath.sortBy || 'updatedAt',
        sortType: (argSort && argSort.sortType) || queryPath.sortType || 'desc',
        status: 'success',
        warehouse_id: userStore?.warehouse_id ? userStore.warehouse_id : undefined,
        type: 'cn',
        range: argFilter && argFilter.range,
        from: from && from.toISOString(),
        to: to && to.toISOString(),
        filterType: 'updatedAt',
      }
      const query = queryString.stringify(payload)
      history.replace(`/history-china?${query}`)
      const response = await withLoading(() => http.get(`product/list?${query}`))
      if (response.status !== 'success') {
        return showWithMessage(response.message)
      }

      const result = map(response.data.data, (item, index) => ({
        ...item,
        index: index + (argOffset || 0) + 1,
      }))
      setTotal(response.data.total)
      setRows(result)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleUndo = async payload => {
    try {
      const result = await http.put('product/undo', { ids: payload })
      if (result.status === 'success') {
        showWithConfig({
          message: ' ​ຂໍ້ມູນທີ່ຖືກປັບປຸງສໍາ​ເລັດ',
          title: 'ລາຍການສິນຄ້າ',
          type: 'success',
        })
        getListProduct()
      } else {
        showWithMessage(result.message)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const openConfirm = selected => {
    showWithConfig({ message: 'ທ່ານແນ່ໃຈບໍ່ທີ່ຈະດຳເນີນການ ?', title: 'ຢືນຢັນ' }, () =>
      handleUndo(selected),
    )
  }

  useEffect(() => {
    getListProduct()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TableHistory
        name="ລາຍການ ປະຫວັດສິນຄ້າ (ຈີນ)"
        rows={rows}
        total={total}
        headCells={headCells}
        openConfirm={openConfirm}
        getListProduct={getListProduct}
        setSelected={setSelected}
        setSum={setSum}
        setBath={setBath}
        selected={selected}
        sum={sum}
        bath={bath}
      />
      <Loading />
      <Dialog />
      <Modal show={show} handleClose={() => setShow(false)} />
    </>
  )
}
