const drawerWidth = 240

export default theme => ({
    root: {
		display: 'flex'
	},
	appBar: {
		backgroundColor: '#335992',
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		color: '#ffffff',
		marginRight: 36,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		backgroundColor: '#122331',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		backgroundColor: '#122331',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(7) + 1,
		},
	},
	toolbar: {
		color: '#ffffff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		// padding: theme.spacing(2),
	},
	textLogo: {
		color: '#ffffff'
	},
	listMenu: {
		color: '#ffffff'
	},
	listIcon: {
		color: '#ffffff'
	},
	menu: {
		position: 'absolute',
		right: 0
	},
	iconMenu: {
		color: '#ffffff'
	},
	colButton: {
		padding: 0.5
	},
	colTab: {
		padding: 7
	},
	logo: {
		width: 40,
		marginRight: 8
	},
	divider: {
		backgroundColor: '#ffffff30'
	},
})
