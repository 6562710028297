/* eslint-disable camelcase */
import React from 'react'
import Joi from 'joi'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import validate from '../../service/form/validation'
import formCreateInputs from '../../service/form/create'
import { TextInput } from '..'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    padding: 20,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ show, handleClose, initialValues, handleSave, actionEdit }) {
  const classes = useStyles()
  const schema = Joi.object().keys({
    code: Joi.string().required(),
    user_name: Joi.string().required(),
    user_tel: Joi.number().required(),
    weight: Joi.number(),
    volume: Joi.number(),
    cost: Joi.number(),
    warehouse_name: Joi.string(),
  })

  const onSubmit = payload => {
    handleSave(payload)
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues,
    validate: validate(schema),
  })

  const [code, user_name, user_tel, weight, volume, cost, warehouse_name] = formCreateInputs(
    ['code', 'user_name', 'user_tel', 'weight', 'volume', 'cost', 'warehouse_name'],
    form,
  )

  const handleProcess = () => {
    const codeValue = code.input.value
    const usernameValue = user_name.input.value
    const usertelValue = user_tel.input.value
    const weightValue = weight.input.value
    const volumeValue = volume.input.value
    const costValude = cost.input.value
    const warehouseName = warehouse_name.input.value
    if (
      codeValue !== initialValues.code ||
      usernameValue !== initialValues.user_name ||
      usertelValue !== initialValues.user_tel ||
      weightValue !== initialValues.weight ||
      volumeValue !== initialValues.volume ||
      costValude !== initialValues.cost ||
      warehouseName !== initialValues.warehouse_name
    ) {
      const payload = {
        _id: initialValues._id,
        code: codeValue,
        user_name: usernameValue,
        user_tel: usertelValue,
        weight: Number(weightValue),
        volume: Number(volumeValue),
        cost: Number(costValude),
        warehouse_name: warehouseName,
      }
      handleSave(payload)
    }
    return false
  }

  return (
    <div>
      <Dialog open={show} onClose={handleClose} TransitionComponent={Transition}>
        <form onSubmit={handleSubmit}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {actionEdit ? 'ແກ້ໄຂສິນຄ້າ' : 'ເພີ່ມສິນຄ້າ'}
              </Typography>
              {actionEdit ? (
                <Button autoFocus type="submit" color="inherit" onClick={handleProcess}>
                  ບັນທຶກ
                </Button>
              ) : (
                <Button autoFocus type="submit" color="inherit">
                  ເພີ່ມ
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <div className={classes.form}>
            <TextInput input={code} label="ລະຫຼັດໄປສະນີ" />
            <TextInput input={user_name} label="ຊື່ເຈົ້າຂອງເຄື່ອງ" />
            <TextInput input={user_tel} label="ເບີໂທລະສັບ" />
            <TextInput input={weight} label="ນ້ຳໜັກ" />
            <TextInput input={volume} label="ບໍລິມາດ" />
            <TextInput input={cost} label="ລາຄາ" />
            <TextInput input={warehouse_name} label="ສາງ" />
          </div>
        </form>
      </Dialog>
    </div>
  )
}
