/* eslint-disable no-irregular-whitespace */
import React, { useState } from 'react'
import Joi from 'joi'
import store from 'store'
import { useForm } from 'react-final-form-hooks'
import {
  Avatar,
  Button,
  CssBaseline,
  IconButton,
  InputAdornment,
  Typography,
  Container,
} from '@material-ui/core/'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import http from '../../service/http'
import { TextInput, useLoading, useDialog } from '../../components'
import formCreateInputs from '../../service/form/create'
import validate from '../../service/form/validation'
import useStyles from './styles'
import logo from '../../assets/singha.png'

export default ({ history }) => {
  const classes = useStyles()
  const token = store.get('token')

  const [eye, setEye] = useState(false)
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage] = useDialog({ title: 'Invalid login', type: 'error' })

  const onSubmit = async payload => {
    try {
      const { data, message, status } = await withLoading(() =>
        http.post('auth/login-admin', payload),
      )
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        store.set('user', data)
        store.set('token', data.token)
        history.push('')
      }
    } catch (error) {
      showWithMessage(error.message)
    }
  }

  const schema = Joi.object().keys({
    username: Joi.string().required(),
    password: Joi.string()
      .regex(/^[a-zA-Z0-9!@#$%&*]{3,30}$/)
      .required(),
  })

  const { form, handleSubmit, submitting } = useForm({
    onSubmit,
    validate: validate(schema),
  })

  const [username, password] = formCreateInputs(['username', 'password'], form)

  if (token) {
    history.push('')
  }

  return (
    <div className={classes.background}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={logo} />
          <Typography component="h1" variant="h5">
            SINGHA LOGISTICS
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form}>
            <TextInput input={username} label="ຊື່ຜູ້ໃຊ້" />
            <TextInput
              input={password}
              type={eye ? 'text' : 'password'}
              label="ລະຫັດຜ່ານ"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={() => setEye(!eye)}
                      onMouseDown={event => event.preventDefault()}
                    >
                      {eye ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={submitting}
              className={classes.submit}
            >
              ເຂົ້າ​ສູ່​ລະ​ບົບ
            </Button>
          </form>
        </div>
        <Loading />
        <Dialog />
      </Container>
    </div>
  )
}
