import React, { useState, useMemo } from 'react'
import {
  Button,
  Container,
  Card,
  Grid,
  CardContent,
  OutlinedInput,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from '@material-ui/core'
import http from '../../service/http'
import { useLoading, useDialog } from '../../components'

export default ({ classes }) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(false)
  const [notify, setNotify] = useState(true)
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({ title: 'ແກ້ໄຂໍ້ຄວາມປະກາດແຈ້ງການ' })

  const getPrice = async () => {
    try {
      const { data, status, message } = await withLoading(() => http.get('setting/announce'))
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        setTitle(data.title)
        setDescription(data.description)
        setActive(data.active)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const onSubmit = async () => {
    try {
      const payload = {
        title,
        description,
        active,
        notify,
      }
      const result = await withLoading(() => http.put('setting/announce', payload))

      if (result.status === 'success') {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
      } else {
        console.log(result.message)
        showWithMessage(result.message)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  useMemo(() => {
    getPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <Grid>
            <label>ຫົວຂໍ້</label>
            <OutlinedInput
              fullWidth
              value={title}
              onChange={event => setTitle(event.target.value)}
            />
          </Grid>
          <Grid className={classes.input}>
            <label>ເນື້ອໃນ</label>
            <OutlinedInput
              fullWidth
              multiline
              rows="4"
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </Grid>
          <Grid>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={
                  <Switch color="primary" checked={active} onChange={() => setActive(!active)} />
                }
                label="ປິດ/ເປີດ ແຈ້ງການ"
                labelPlacement="top"
              />
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={notify} onChange={() => setNotify(!notify)} />
                }
                label="ສົ່ງແຈ້ງເຕືອນໃຫ້ລູກຄ້າທຸກຄົນ"
                labelPlacement="top"
              />
            </FormGroup>
          </Grid>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.button}
        >
          ບັນທືກ
        </Button>
      </Card>
      <Loading />
      <Dialog />
    </Container>
  )
}
