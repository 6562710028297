import React, { useState, useEffect } from 'react'
import { isEqual, map } from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import { Table } from '../../components/table'
import { useLoading, useDialog } from '../../components'
import http from '../../service/http'

export default () => {
  const [Loading, withLoading] = useLoading(false)
  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(0)
  const [Dialog, showWithMessage] = useDialog({})
  const [data, setData] = useState({ item: [] })

  const columns = [
    { title: 'ລຳດັບ', field: 'index', editable: 'never' },
    { title: 'ຊື່ສາງ', field: 'name' },
    { title: 'ລະຫັດສາງ (EN)', field: 'code' },
    {
      title: 'ວັນທີ່ສ້າງ',
      field: 'createdAt',
      editable: 'never',
      render: item =>
        item && item.createdAt ? moment(item.createdAt).format('LLL') : moment().format('LLL'),
    },
  ]

  const setIndex = (value, argOffset) => {
    if (value.length > 0) {
      return map(value, (item, index) => {
        return {
          ...item,
          index: index + (argOffset || 0) + 1,
        }
      })
    } else {
      return {
        ...value,
        index: data.item.length + 1,
      }
    }
  }

  const handleAdd = async newData => {
    try {
      const payload = {
        ...newData,
        name: newData.name && newData.name.trim(),
      }
      const result = await withLoading(() => http.post('warehouse/create', payload))
      if (result.status !== 'success') {
        showWithMessage(result.message)
      } else {
        const index = setIndex(result.data)
        setData(prevState => {
          const item = [...prevState.item]
          item.push(index)
          return { ...prevState, item }
        })
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleUpdate = async (newData, oldData) => {
    try {
      const objectNewData = {
        ...newData,
        tableData: oldData.tableData,
      }
      if (!isEqual(objectNewData, oldData)) {
        const payload = {
          ...newData,
          name: newData.name && newData.name.trim(),
        }
        const result = await withLoading(() => http.put(`warehouse/update/${newData._id}`, payload))
        if (result.status !== 'success') {
          showWithMessage(result.message)
        } else {
          setData(prevState => {
            const item = [...prevState.item]
            item[item.indexOf(oldData)] = payload
            return { ...prevState, item }
          })
        }
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const getListWarehouse = async (argOffset, argLimit, argSearch) => {
    try {
      setIsLoading(true)
      const query = queryString.stringify({
        limit: argLimit || limit,
        offset: argOffset || 0,
        search: argSearch,
        sortBy: 'createdAt',
        sortType: 'asc',
      })
      const warehouse = await http.get(`warehouse/list?${query}`)
      if (warehouse.status !== 'success') {
        setIsLoading(false)
        return showWithMessage(warehouse.message)
      }
      if (warehouse.data.data.length > 0) {
        const index = setIndex(warehouse.data.data, Number(argOffset))
        setData({ item: index, total: warehouse.data.total })
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      showWithMessage(error.message)
    }
  }

  const handleChangePage = (e, value) => {
    setOffset(limit * value)
    setPage(value)
    getListWarehouse(limit * value)
  }

  const handleChangeRowPerPage = value => {
    setLimit(value)
    getListWarehouse(offset, value)
  }

  const handleSearchChanged = value => {
    getListWarehouse(offset, limit, value)
  }

  useEffect(() => {
    getListWarehouse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editable = {
    onRowAdd: newData => handleAdd(newData),
    onRowUpdate: (newData, oldData) => handleUpdate(newData, oldData),
  }

  const options = {
    rowStyle: rowData => ({
      backgroundColor: rowData.index % 2 === 0 ? '#ffffff' : '#fafafa',
    }),
  }

  return (
    <>
      <Table
        title="ລາຍການສາງ"
        data={data.item}
        columns={columns}
        isLoading={isLoading}
        total={data.total}
        limit={limit}
        page={page}
        editable={editable}
        options={options}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowPerPage}
        handleSearchChanged={handleSearchChanged}
      />
      <Loading />
      <Dialog />
    </>
  )
}
