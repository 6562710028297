import React from 'react'
import Tab from '@material-ui/core/Tab'

const LinkTab = props => (
  <Tab
    component="a"
    variant="secondary"
    onClick={event => {
      event.preventDefault()
    }}
    {...props}
  />
)

export default LinkTab
