import React from 'react'
import store from 'store'
import { AppBar, Tabs } from '@material-ui/core'
import { TabLink, TabPanel } from '../../components/tab'
import { useStyles, a11yProps } from './styles'
import Notify from './importNotify'
// import Prices from './configPrice'
import Message from './configNotify'
import Announce from './configAnnounce'
import Info from './configInfo'

export default function NavTabs() {
  const user = store.get('user')
  const admin = user && user.role === 'admin'

  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <TabLink label="ນຳເຂົ້າແຈ້ງເຕືອນ" {...a11yProps(0)} />
          <TabLink label="ແກ້ໄຂຂໍ້ຄວາມ ແຈ້ງເຕືອນ" {...a11yProps(1)} />
          {admin && <TabLink label="ແກ້ໄຂຂໍ້ຄວາມ ປະກາດແຈ້ງການ" {...a11yProps(2)} />}
          {admin && <TabLink label="ແກ້ໄຂຂໍ້ຄວາມ ຂໍ້ມູນບໍລິສັດ" {...a11yProps(3)} />}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Notify classes={classes} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Message classes={classes} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Announce classes={classes} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Info classes={classes} />
      </TabPanel>
    </div>
  )
}
