import React, { useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { AppBar, Toolbar, CssBaseline, Typography, IconButton } from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Drawer, Menu } from '..'
import styles from './styles'
import logo from '../../assets/singha.png'

const useStyles = makeStyles(theme => styles(theme))

export default ({ content }) => {
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleDirector = event => {
    switch (event) {
      case 'ຈັດການສິນຄ້າ (ຈີນ)':
        history.push('/product-china')
        break
      case 'ປະຫວັດສິນຄ້າ (ຈີນ)':
        history.push('/history-china')
        break
      case 'ນຳເຂົ້າຂໍ້ມູນ (ຈີນ)':
        history.push('/import-china')
        break
      case 'ຈັດການສິນຄ້າ (ໄທ)':
        history.push('/product-thai')
        break
      case 'ປະຫວັດສິນຄ້າ (ໄທ)':
        history.push('/history-thai')
        break
      case 'ນຳເຂົ້າຂໍ້ມູນ (ໄທ)':
        history.push('/import-thai')
        break
      case 'ລູກຄ້າ':
        history.push('/customer')
        break
      case 'ຕັ້ງຄ້າແຈ້ງເຕືອນ':
        history.push('/setting')
        break
      case 'ພະນັກງານ':
        history.push('/staff')
        break
      case 'ສາງ':
        history.push('/warehouse')
        break
      case 'ຕັ້ງຄ້າທີ່ຢູ່':
        history.push('/set-address')
        break
      case 'ຕັ້ງຄ້າຄິດໄລ່ຄ້າບໍລິການ':
        history.push('/calculate')
        break
      default:
        break
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(classes.menuButton, { [classes.hide]: open })}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Singha Logistics" className={classes.logo} />
          <Typography variant="h4" className={classes.textLogo} noWrap>
            Singha Logistics
          </Typography>
          <Menu classes={classes} history={history} />
        </Toolbar>
      </AppBar>
      <Drawer
        direction={handleDirector}
        classes={classes}
        theme={theme}
        open={open}
        handleDrawerClose={() => setOpen(false)}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {content}
      </main>
    </div>
  )
}
