import React from 'react'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#2f3678',
    },
  },
  typography: { useNextVariants: true },
})

const styles = {
  root: {
    color: '#2f3678',
    '&$checked': {
      color: '#2f3678',
    },
  },
  checked: {},
  input: {
    color: '#2f3678',
  },
  label: {
    color: '#2f3678',
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid #2f3678`,
    },
  },
}

const Input = ({ classes, input: { input }, label }) => {
  const handleChange = event =>
    input.onChange({
      ...event,
      target: {
        ...event.target,
        value: !input.value,
      },
    })

  return (
    <MuiThemeProvider theme={theme}>
      <FormControlLabel
        control={
          <Checkbox
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
            checked={Boolean(input.value)}
            onChange={handleChange}
            color="primary"
          />
        }
        label={<Typography style={{ color: '#2f3678' }}>{label}</Typography>}
      />
    </MuiThemeProvider>
  )
}

export default withStyles(styles)(Input)
