import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Button,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import EnhancedTableHead from './enhancedTableHead'
import EnhancedTableToolbar from './enhancedTableToolbar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)

export default props => {
  const {
    name,
    rows,
    total,
    headCells,
    getListProduct,
    openConfirmDelete,
    openConfirmSendNotify,
    editProduct,
    addUser,
    selected,
    setSelected,
  } = props
  const classes = useStyles()
  const [sort, setSort] = useState({ sortBy: 'time', sortType: 'asc' })
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState({})

  const handleRequestSort = (event, property) => {
    const isDesc = sort.sortBy === property && sort.sortType === 'desc'
    const payload = {
      sortBy: property,
      sortType: isDesc ? 'asc' : 'desc',
    }
    setSort(payload)
    getListProduct(offset, rowsPerPage, payload, keyword, filter)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id)
      setSelected(newSelecteds)
    }
    setSelected([])
  }

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row._id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row._id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setOffset(rowsPerPage * newPage)
    setPage(newPage)
    getListProduct(rowsPerPage * newPage, rowsPerPage, sort, keyword, filter)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    getListProduct(0, event.target.value, sort, keyword, filter)
  }

  const handleSearch = value => {
    setKeyword(value)
    getListProduct(0, rowsPerPage, sort, value, filter)
    setPage(0)
  }

  const handleFilter = (from, to) => {
    const payload = {
      from,
      to,
    }
    setFilter(payload)
    getListProduct(0, rowsPerPage, sort, keyword, payload)
    setPage(0)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          name={name}
          numSelected={selected.length}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          selected={selected}
          addUser={addUser}
          openConfirmDelete={openConfirmDelete}
          openConfirmSendNotify={openConfirmSendNotify}
        />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="ແຖວຕໍ່ຫນ້າ"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={sort.sortType}
              orderBy={sort.sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id)
                const labelId = `table-customer-checkbox-${index}`

                return (
                  <StyledTableRow
                    hover
                    onClick={event => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ maxWidth: 10 }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="default"
                    >
                      {row.index}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.name}</StyledTableCell>
                    <StyledTableCell align="right">{row.password}</StyledTableCell>
                    <StyledTableCell align="right">{row.mobilePhone}</StyledTableCell>
                    <StyledTableCell align="center" style={{ minWidth: 80 }}>
                      <b>{row.waitingProduct}</b>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ minWidth: 80 }}>
                      <b>{row.successProduct}</b>
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ minWidth: 80 }}>
                      <b>{row.allProduct}</b>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.last_login
                        ? moment(row.last_login).format('LL')
                        : 'ຍັງບໍ່ເຄີຍເຂົ້າລະບົບ'}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button onClick={() => editProduct(row)} startIcon={<Edit />}>
                        ແກ້ໄຂລູກຄ້າ
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
              {rows.length === 0 && <StyledTableRow style={{ height: 53 * emptyRows }} />}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="ແຖວຕໍ່ຫນ້າ"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
