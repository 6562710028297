/* eslint-disable camelcase */
import React from 'react'
import Joi from 'joi'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import validate from '../../service/form/validation'
import formCreateInputs from '../../service/form/create'
import { TextInput } from '..'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    padding: 20,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ show, handleClose, initialValues, handleSave, actionEdit }) {
  const classes = useStyles()
  const schema = Joi.object().keys({
    name: Joi.string().required(),
    password: Joi.string().required(),
    mobilePhone: Joi.string().required(),
  })

  const onSubmit = payload => {
    handleSave(payload)
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues,
    validate: validate(schema),
  })

  const [name, password, mobilePhone] = formCreateInputs(['name', 'password', 'mobilePhone'], form)

  const handleProcess = () => {
    const nameValue = name.input.value
    const passwordValue = password.input.value
    const mobilePhoneValue = mobilePhone.input.value
    if (
      nameValue !== initialValues.name ||
      passwordValue !== initialValues.password ||
      mobilePhoneValue !== initialValues.mobilePhone
    ) {
      const payload = {
        _id: initialValues._id,
        name: nameValue,
        password: passwordValue,
        mobilePhone: mobilePhoneValue,
      }
      handleSave(payload)
    }
    return false
  }

  return (
    <div>
      <Dialog open={show} onClose={handleClose} TransitionComponent={Transition}>
        <form onSubmit={handleSubmit}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {actionEdit ? 'ແກ້ໄຂລູກຄ້າ' : 'ເພີ່ມລູກຄ້າ'}
              </Typography>
              {actionEdit ? (
                <Button autoFocus type="submit" color="inherit" onClick={handleProcess}>
                  ບັນທຶກ
                </Button>
              ) : (
                <Button autoFocus type="submit" color="inherit">
                  ເພີ່ມ
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <div className={classes.form}>
            <TextInput input={name} label="ຊື່" />
            <TextInput input={password} label="ລະຫັດຜ່ານ" />
            <TextInput input={mobilePhone} label="ເບີໂທ" />
          </div>
        </form>
      </Dialog>
    </div>
  )
}
