/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { map } from 'lodash'
import store from 'store'
import axios from 'axios'
import queryString from 'query-string'
import Table from './table/enhancedTable'
import { useLoading, useDialog } from '../../components'
import http from '../../service/http'
import Modal from './table/editAddress'
import configs from '../../config'

const headCells = [
  { id: 'index', numeric: false, disablePadding: false, label: 'ລຳດັບ' },
  { id: 'image', numeric: true, disablePadding: false, label: 'ແຜນທີ່' },
  { id: 'description', numeric: true, disablePadding: false, label: 'ອະທິບາຍຮູບ' },
  { id: 'address', numeric: true, disablePadding: false, label: 'ທີ່ຢູ່' },
  { id: 'phone_number', numeric: true, disablePadding: false, label: 'ເບີໂທຕິດຕໍ່' },
  { id: 'google_map', numeric: true, disablePadding: false, label: 'Google map Link' },
  { id: 'warehouse_name', numeric: true, disablePadding: false, label: 'ສາງ' },
  { id: 'action', numeric: true, disablePadding: false, label: 'ຈັດການ' },
]

export default () => {
  const token = store.get('token')
  const userStore = store.get('user')
  const admin = userStore && userStore.role === 'admin'

  const [rows, setRows] = useState([])
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({})
  const [actionEdit, setActionEdit] = useState(false) // false = create and true = edit
  const [show, setShow] = useState(false)
  const [total, setTotal] = useState(0)
  const [initialValue, setInitialValue] = useState({})

  const getListMap = async (argOffset, argLimit, argSort) => {
    try {
      const payload = {
        offset: argOffset || 0,
        limit: argLimit || 10,
        sortBy: (argSort && argSort.sortBy) || 'createdAt',
        sortType: (argSort && argSort.sortType) || 'asc',
      }
      const query = queryString.stringify(payload)
      const response = await withLoading(() => http.get(`map/list?${query}`))
      if (response.status !== 'success') {
        return showWithMessage(response.message)
      }

      const result = map(response.data.data, (item, index) => ({
        ...item,
        index: index + (argOffset || 0) + 1,
      }))
      setTotal(response.data.total)
      setRows(result)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleDelete = async selected => {
    try {
      const { status, message } = await http.post(`map/delete/${selected}`, {})
      if (status !== 'success') {
        return showWithMessage(message)
      }
      showWithConfig({
        message: ' ​ຂໍ້ມູນທີ່ຖືກປັບປຸງສໍາ​ເລັດ',
        title: 'ແກ້ໄຂທີ່ຢູ່ລາວ',
        type: 'success',
      })
      return getListMap()
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const deleteMap = selected => {
    showWithConfig({ message: 'ທ່ານແນ່ໃຈບໍ່ທີ່ຈະດຳເນີນການ ?', title: 'ຢືນຢັນ' }, () =>
      handleDelete(selected),
    )
  }

  const addProduct = () => {
    setInitialValue({})

    setActionEdit(false)
    setShow(true)
  }

  const handleUpload = async payload => {
    try {
      let result
      const { description, phone_number, google_map, address, image, warehouse_name } = payload
      const fromData = new FormData()
      if (image.name) fromData.append('image', image, image.name)
      fromData.append('description', description)
      fromData.append('phone_number', phone_number)
      fromData.append('google_map', google_map)
      fromData.append('address', address)
      fromData.append('warehouse_name', warehouse_name)
      if (actionEdit) {
        result = await axios.put(`${configs.baseUrl}/upload/map/${payload._id}`, fromData, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
      } else {
        result = await axios.post(`${configs.baseUrl}/upload/map?`, fromData, {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
      }
      if (result.data.status !== 'success') {
        showWithConfig({ type: 'error', message: result.data.message, title: 'ຂໍ້ມູນຜິດພາດ' })
      } else {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
        setShow(false)
        getListMap()
      }
    } catch (err) {
      console.log(err)
      showWithMessage(err.message)
    }
  }

  const editProduct = payload => {
    setInitialValue(payload)
    setActionEdit(true)
    setShow(true)
  }

  useEffect(() => {
    getListMap()
    if (!admin) {
      headCells.pop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Table
        name="ລາຍການທີ່ຢູ່"
        rows={rows}
        total={total}
        headCells={headCells}
        deleteMap={deleteMap}
        getListMap={getListMap}
        addProduct={addProduct}
        editProduct={editProduct}
      />
      <Loading />
      <Dialog />
      <Modal
        show={show}
        handleClose={() => setShow(false)}
        initialValues={initialValue}
        actionEdit={actionEdit}
        handleUpload={handleUpload}
      />
    </>
  )
}
