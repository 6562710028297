import React from 'react'
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core'

export default ({ classes, label, icon, click }) => (
    <ListItem button className={classes.listMenu} onClick={() => click(label)} >
        <ListItemIcon className={classes.listIcon}>
            {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
    </ListItem>
)
