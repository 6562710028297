import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import { makeStyles } from '@material-ui/core/styles'
import store from 'store'
import moment from 'moment'
import { map } from 'lodash'
import { Button } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import columns from './columns'
import http from '../../service/http'
import { useLoading, useDialog } from '../../components'
import { TableFixed } from '../../components/table'

const useStyles = makeStyles(() => ({
  container: {
    padding: 8,
  },
  button: {
    float: 'right',
    marginTop: 10,
    marginBottom: 20,
    marginRight: 10,
  },
}))

export default () => {
  const userStore = store.get('user')

  const classes = useStyles()
  const [file, setFileUploaded] = useState([])
  const [Loading, withLoading, setLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({})

  const handleSelectFile = event => {
    // event.preventDefault()
    try {
      const { files } = event.target
      const f = files[0]
      const reader = new FileReader()
      setLoading(true)
      reader.onload = e => {
        const data = e.target.result
        const readedData = XLSX.read(data, { type: 'binary', cellDates: true })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        /* Convert array to json */
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 })
        dataParse.shift()

        const setData = map(dataParse, (item, index) => {
          const row = { ...item }
          return {
            index: index + 1,
            time: row[0],
            createdAt: moment(row[0]).format('DD/MM/YYYY'),
            user_tel: row[1],
            user_name: row[2],
            code: row[3],
            bath: Number(row[4]),
            cost: Math.ceil(row[6]),
            warehouse_name: row[7],
          }
        })
        setFileUploaded(setData)
        setLoading(false)
      }
      reader.readAsBinaryString(f)
    } catch (error) {
      console.log(error)
    }
  }

  const hanldUpload = async () => {
    try {
      const convertData = map(file, item => ({
        ...item,
        time: moment(item.time).subtract(7, 'hours'),
        createdAt: moment(item.time),
        bath: Number(item.bath),
        cost: Number(item.cost),
        status: 'waiting',
        type: 'th',
        warehouse_id: userStore?.warehouse_id ? userStore.warehouse_id : undefined,
      }))
      const result = await withLoading(() => http.post('product/upload', { data: convertData }))
      if (result.status !== 'success') {
        showWithMessage(result.message)
      } else {
        showWithConfig({
          message: ' ​ຂໍ້ມູນອັບໂຫລດສໍາ​ເລັດ',
          title: 'ອັບໂຫລດ',
          type: 'success',
        })
        setFileUploaded([])
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  return (
    <div className={classes.container}>
      <Button variant="contained" component="label" onChange={handleSelectFile}>
        ເລືອກເອກະສານ (ໄທ)
        <input type="file" style={{ display: 'none' }} />
      </Button>
      {file && (
        <>
          <TableFixed rows={file} columns={columns} />
          {file.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<CloudUpload />}
              onClick={hanldUpload}
            >
              ອັບໂຫລດ
            </Button>
          )}
        </>
      )}
      <Loading />
      <Dialog />
    </div>
  )
}
