import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import moment from 'moment'
import { map } from 'lodash'
import { Button } from '@material-ui/core'
import { CloudUpload } from '@material-ui/icons'
import columns from './columns'
import http from '../../service/http'
import { useLoading, useDialog } from '../../components'
import { TableFixed } from '../../components/table'

export default ({ classes }) => {
  const [file, setFileUploaded] = useState([])
  const [Loading, withLoading, setLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({})

  const handleSelectFile = event => {
    // event.preventDefault()
    try {
      const { files } = event.target
      const f = files[0]
      const reader = new FileReader()
      setLoading(true)
      reader.onload = e => {
        const data = e.target.result
        const readedData = XLSX.read(data, { type: 'binary' })
        const wsname = readedData.SheetNames[0]
        const ws = readedData.Sheets[wsname]

        /* Convert array to json */
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 })
        dataParse.shift()
        const setData = map(dataParse, (item, index) => {
          const row = { ...item }
          return {
            index: index + 1,
            mobilePhone: row[0],
            message: row[1],
          }
        })
        setFileUploaded(setData)
        setLoading(false)
      }
      reader.readAsBinaryString(f)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpload = async () => {
    try {
      const convertData = map(file, item => ({
        ...item,
        createdAt: moment.utc().format(),
      }))
      const result = await withLoading(() =>
        http.post('notification/upload', { data: convertData }),
      )
      if (result.status === 'success') {
        showWithConfig({
          message: ' ​ຂໍ້ມູນອັບໂຫລດສໍາ​ເລັດ',
          title: 'ອັບໂຫລດສໍາ​ເລັດ',
          type: 'success',
        })
        setFileUploaded([])
      } else {
        showWithConfig({
          message: ' ​ຂໍ້ມູນອັບໂຫລດຜິດພາດ',
          title: 'ຂໍ້ມູນຜິດພາດ',
          type: 'error',
        })
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  return (
    <div>
      <Button variant="contained" component="label" onChange={handleSelectFile}>
        ເລືອກເອກະສານ
        <input type="file" style={{ display: 'none' }} />
      </Button>
      {file && (
        <>
          <TableFixed rows={file} columns={columns.notify} />
          {file.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<CloudUpload />}
              onClick={handleUpload}
            >
              ສົ່ງ
            </Button>
          )}
        </>
      )}
      <Loading />
      <Dialog />
    </div>
  )
}
