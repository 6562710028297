
const useStyles = (theme) => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	background: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: -1,
		position: 'fixed',
		backgroundColor: '#261a5e',
	},
	container: {
		backgroundColor: 'white',
		borderRadius: 10
	},
	paper: {
		marginTop: theme.spacing(16),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
		width: theme.spacing(8),
    height: theme.spacing(8),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		height: 50,
		color: 'white',
		textTransform: 'capitalize'
	},
	buttonLink:  {
		fontSize: 16,
		marginBottom: 12
	}
})

export default useStyles