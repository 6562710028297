import React, { useState, useEffect } from 'react'
import { map, isEqual } from 'lodash'
import queryString from 'query-string'
import { Table } from '../../components/table'
import { useLoading, useDialog } from '../../components'
import http from '../../service/http'

export default () => {
  const [Loading, withLoading] = useLoading(false)
  const [isLoading, setIsLoading] = useState(false)
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(0)
  const [Dialog, showWithMessage] = useDialog({})
  const [data, setData] = useState({ item: [] })
  const [warehouse, setWarehouse] = useState({})

  const columns = [
    { title: 'ລຳດັບ', field: 'index', editable: 'never' },
    { title: 'ຊື່', field: 'name' },
    { title: 'ຊື່ຜູ້ໃຊ້', field: 'mobilePhone' },
    { title: 'ລະຫັດຜ່ານ', field: 'password' },
    {
      title: 'ສາງ',
      field: 'warehouse_code',
      lookup: warehouse,
    },
    {
      title: 'ສະທານະ',
      field: 'status',
      lookup: { active: 'active', block: 'block' },
    },
  ]

  const decryptPassword = (value, argOffset) => {
    if (value.length > 0) {
      return map(value, (item, index) => {
        return {
          index: index + (argOffset || 0) + 1,
          ...item,
        }
      })
    } else {
      return {
        ...value,
        index: data.item.length + 1,
      }
    }
  }

  const handleAdd = async newData => {
    try {
      const payload = {
        ...newData,
        operator: 'manager',
        name: newData.name && newData.name.trim(),
      }
      const result = await withLoading(() => http.post('user/create', payload))
      if (result.status !== 'success') {
        showWithMessage(result.message)
      } else {
        const decrypted = decryptPassword(result.data)
        setData(prevState => {
          const item = [...prevState.item]
          item.push(decrypted)

          return { ...prevState, item }
        })
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }
  const handleUpdate = async (newData, oldData) => {
    try {
      const objectNewData = {
        ...newData,
        tableData: oldData.tableData,
      }
      if (!isEqual(objectNewData, oldData)) {
        const payload = {
          ...newData,
          name: newData.name && newData.name.trim(),
        }
        const result = await withLoading(() => http.put(`user/update/${newData._id}`, payload))
        if (result.status !== 'success') {
          showWithMessage(result.message)
        } else {
          setData(prevState => {
            const item = [...prevState.item]
            item[item.indexOf(oldData)] = payload
            return { ...prevState, item }
          })
        }
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }
  const handleDelete = async oldData => {
    try {
      const result = await withLoading(() => http.post('user/delete', { _id: oldData._id }))
      if (result.status !== 'success') {
        showWithMessage(result.message)
      } else {
        setData(prevState => {
          const item = [...prevState.item]
          item.splice(item.indexOf(oldData), 1)
          return { ...prevState, item }
        })
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const getListUser = async (argOffset, argLimit, argSearch) => {
    try {
      setIsLoading(true)
      const query = queryString.stringify({
        limit: argLimit || limit,
        offset: argOffset || 0,
        search: argSearch,
        sortBy: 'createdAt',
        sortType: 'asc',
        operator: 'manager',
      })
      const user = await http.get(`user/list?${query}`)
      if (user.status !== 'success') {
        setIsLoading(false)
        return showWithMessage(user.message)
      }
      if (user.data.data.length > 0) {
        const decrypted = decryptPassword(user.data.data, Number(argOffset))
        setData({ item: decrypted, total: user.data.total })
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      showWithMessage(error.message)
    }
  }

  const getListWarehouse = async () => {
    try {
      const query = queryString.stringify({
        limit: 100,
        sortBy: 'createdAt',
        sortType: 'asc',
      })
      const response = await http.get(`warehouse/list?${query}`)
      if (response.status === 'success') {
        const result = response.data.data.reduce(
          (ac, value) => ({ ...ac, [value.code]: value.name }),
          {},
        )
        setWarehouse(result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangePage = (e, value) => {
    setOffset(limit * value)
    setPage(value)
    getListUser(limit * value)
  }

  const handleChangeRowPerPage = value => {
    setLimit(value)
    getListUser(offset, value)
  }

  const handleSearchChanged = value => {
    getListUser(offset, limit, value)
  }

  useEffect(() => {
    getListUser()
    getListWarehouse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const editable = {
    onRowAdd: isEqual(warehouse, {}) ? undefined : newData => handleAdd(newData),
    onRowUpdate: (newData, oldData) => handleUpdate(newData, oldData),
    onRowDelete: oldData => handleDelete(oldData),
  }

  const options = {
    rowStyle: rowData => ({
      backgroundColor: rowData.index % 2 === 0 ? '#ffffff' : '#fafafa',
    }),
  }

  return (
    <>
      <Table
        title="ລາຍຊື່ ພະນັກງານ"
        data={data.item}
        columns={columns}
        isLoading={isLoading}
        total={data.total}
        limit={limit}
        page={page}
        editable={editable}
        options={options}
        handleChangePage={handleChangePage}
        handleChangeRowPerPage={handleChangeRowPerPage}
        handleSearchChanged={handleSearchChanged}
      />
      <Loading />
      <Dialog />
    </>
  )
}
