import React from 'react'
import store from 'store'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, Typography, Button, Tooltip } from '@material-ui/core'
import { AddBox } from '@material-ui/icons'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
  buttonAdd: {
    width: 180,
    color: 'white',
    marginRight: 5,
  },
  buttonGot: {
    width: 180,
  },
  textTotal: {
    marginRight: 20,
  },
}))

const EnhancedTableToolbar = ({ name, addProduct }) => {
  const userStore = store.get('user')
  const admin = userStore && userStore.role === 'admin'

  const classes = useToolbarStyles()

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {name}
        </Typography>
        {admin && (
          <Tooltip title="ເພີ່ມທີ່ຢູ່">
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonAdd}
              onClick={addProduct}
              startIcon={<AddBox />}
            >
              ເພີ່ມທີ່ຢູ່
            </Button>
          </Tooltip>
        )}
      </Toolbar>
    </>
  )
}

export default EnhancedTableToolbar
