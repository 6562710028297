import React from 'react'
import { useStyles } from './styles'
import User from './user'

export default () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <User classes={classes} />
    </div>
  )
}
