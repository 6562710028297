import React, { useState } from 'react'
import { ScaleLoader } from 'react-spinners'

export default status => {
  const [loading, setLoading] = useState(status)

  const withLoading = async callback => {
    setLoading(true)
    try {
      const response = await callback()
      setLoading(false)
      return response
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  return [
    () =>
      loading && (
        <div className="box-loader">
          <div className="loading">
            <ScaleLoader sizeUnit="px" width={4} size={50} color="#00b9eb" loading={loading} />
          </div>
        </div>
      ),
    withLoading,
    setLoading,
  ]
}
