import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    'nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  input: {
    marginTop: 10,
  },
  button: {
    float: 'right',
    marginTop: 10,
    marginBottom: 20,
    marginRight: 10
  },
}))

export const a11yProps = (index) => {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}
