/* eslint-disable camelcase */
import React from 'react'
import Joi from 'joi'
import { useForm } from 'react-final-form-hooks'
import { Button, Dialog, Slide, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import validate from '../../service/form/validation'
import formCreateInputs from '../../service/form/create'
import { TextInput } from '..'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ show, msg, user, handleClose, handleSendNotify }) {
  const schema = Joi.object().keys({
    message: Joi.string().required(),
  })

  const onSubmit = async value => {
    handleSendNotify(value)
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      message: msg,
    },
    validate: validate(schema),
  })

  const [message] = formCreateInputs(['message'], form)

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          ສົ່ງ​ຂໍ້​ຄວາມເຖີງ: {user && user.map(item => `${item.name}, `)}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextInput multiline rows="4" input={message} label="ເນື້ອໃນ" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              ຍົກເລີກ
            </Button>
            <Button autoFocus type="submit" variant="contained" color="primary">
              ສົ່ງ
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
