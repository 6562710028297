import { makeStyles } from '@material-ui/core/styles'
import useStyles from '../../service/styles'

const style = makeStyles((theme) => {
  const globalStyle = useStyles(theme)
  return {
    ...globalStyle
  }
})

export default style