export default {
  product: [
    { id: 'index', label: 'ລຳດັບ' },
    { id: 'createdAt', label: 'ວັນທີ', minWidth: 170 },
    { id: 'user_tel', label: 'ເບີໂທ', minWidth: 170 },
    {
      id: 'user_name',
      label: 'ຊື່',
      minWidth: 170
    },
    {
      id: 'code',
      label: 'ລະຫັດ',
      align: 'right',
      minWidth: 170
    },
    {
      id: 'weight',
      label: 'ນ້ຳໜັກ',
      align: 'right',
      minWidth: 100,
      format: value => value.toLocaleString()
    },
    {
      id: 'volume',
      label: 'ບໍລິມາດ',
      align: 'right',
      minWidth: 100,
      format: value => value.toFixed(2)
    }
  ],
  notify: [
    { id: 'index', label: 'ລຳດັບ' },
    { id: 'mobilePhone', label: 'ເບີໂທ', minWidth: 170 },
    { id: 'message', label: 'ຂໍ້ຄວາມ', minWidth: 170 },
  ]
}
