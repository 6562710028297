export default () => ({
    // root: {
    //     position: 'relative !important'
    // },
    dialog: {
        minWidth: 300
    },
    error: {
        textAlign: 'center',
        color: '#f20058'
    },
    success: {
        textAlign: 'center',
        color: '#00c14f'
    }
})