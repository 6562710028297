import React, { useState, useMemo } from 'react'
import {
  Button,
  Container,
  Card,
  Grid,
  CardContent,
  OutlinedInput,
} from '@material-ui/core'
import http from '../../service/http'
import { useLoading, useDialog } from '../../components'

export default ({ classes }) => {
  const [content, setContent] = useState('')
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({ title: 'ແກ້ໄຂຂໍ້ມູນບໍລິສັດ' })

  const getPrice = async () => {
    try {
      const { data, status, message } = await withLoading(() => http.get('setting/working-time'))
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        setContent(data.content)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const onSubmit = async () => {
    try {
      const result = await withLoading(() => http.put('setting/working-time', { content }))

      if (result.status === 'success') {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
      } else {
        showWithMessage(result.message)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  useMemo(() => {
    getPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <Grid className={classes.input}>
            <label>ເນື້ອໃນ</label>
            <OutlinedInput
              fullWidth
              multiline
              rows="4"
              value={content}
              onChange={event => setContent(event.target.value)}
            />
          </Grid>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.button}
        >
          ບັນທືກ
        </Button>
      </Card>
      <Loading />
      <Dialog />
    </Container>
  )
}
