import React from 'react'
import { AppBar, Tabs } from '@material-ui/core'
import { TabLink, TabPanel } from '../../components/tab'
import { useStyles, a11yProps } from './styles'
import AddressThai from './configAddressThai'
import AddressChina from './configAddressChina'
import AddressLao from './configAddressLao'

export default function SetAddress() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <TabLink label="ແກ້ໄຂ ທີ່ຢູ່ຈີນ" {...a11yProps(0)} />
          <TabLink label="ແກ້ໄຂ ທີ່ຢູ່ໄທ" {...a11yProps(1)} />
          <TabLink label="ແກ້ໄຂ ທີ່ຢູ່ລາວ" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AddressChina classes={classes} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AddressThai classes={classes} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AddressLao classes={classes} />
      </TabPanel>
    </div>
  )
}
