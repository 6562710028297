import React, { useState, useEffect } from 'react'
import { map } from 'lodash'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import TableCustomer from '../../components/tableCustomer'
import { useLoading, useDialog } from '../../components'
import http from '../../service/http'
import ModalEdit from '../../components/editUser'
import ModalNotify from '../../components/modalNotify'

const headCells = [
  { id: 'index', numeric: false, disablePadding: true, label: 'ລຳດັບ' },
  { id: 'name', numeric: true, disablePadding: false, label: 'ຊື່' },
  { id: 'password', numeric: true, disablePadding: false, label: 'ລະຫັດຜ່ານ' },
  { id: 'mobilePhone', numeric: true, disablePadding: false, label: 'ເບີໂທ' },
  { id: 'waitingProduct', numeric: true, disablePadding: false, label: 'ສິນຄ້າຍັງຄ້າງ' },
  { id: 'successProduct', numeric: true, disablePadding: false, label: 'ສິນຄ້າຮັບແລ້ວ' },
  { id: 'allProduct', numeric: true, disablePadding: false, label: 'ສິນຄ້າທັງໝົດ' },
  { id: 'last_login', numeric: true, disablePadding: false, label: 'ເຂົ້າລະບົບລ້າສຸດ' },
  { id: 'action', numeric: true, disablePadding: false, label: 'ຈັດການ' },
]

export default () => {
  const history = useHistory()

  const [rows, setRows] = useState([])
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({})
  const [actionEdit, setActionEdit] = useState(false) // false = create and true = edit
  const [showModal, setShowModal] = useState(false)
  const [showNotify, setShowNotify] = useState(false)
  const [total, setTotal] = useState(0)
  const [initialValue, setInitialValue] = useState({})
  const [customMsg, setCustomMsg] = useState('')
  const [selectUser, setSelectUser] = useState([])
  const [idSelect, setIdSelent] = useState([])
  const [selected, setSelected] = useState([])

  const getListUser = async (argOffset, argLimit, argSort, argSearch, argFilter) => {
    try {
      const { search } = history.location
      const queryPath = queryString.parse(search)
      const payload = {
        limit: argLimit || queryPath.limit || 10,
        offset: argOffset || 0,
        search: argSearch,
        sortBy: (argSort && argSort.sortBy) || queryPath.sortBy || 'createdAt',
        sortType: (argSort && argSort.sortType) || queryPath.sortType || 'desc',
        from: (argFilter && argFilter.from) || queryPath.from,
        to: argFilter && argFilter.to,
        range: argFilter && argFilter.range,
      }
      const query = queryString.stringify(payload)
      history.replace(`/customer?${query}`)
      const response = await withLoading(() => http.get(`user/list?${query}`))
      if (response.status !== 'success') {
        return showWithMessage(response.message)
      }
      const result = map(response.data.data, (item, index) => {
        return {
          ...item,
          mobilePhone: item.mobilePhone.toString(),
          password: item.password,
          index: index + (argOffset || 0) + 1,
        }
      })
      setSelected([])
      setTotal(response.data.total)
      setRows(result)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const getMessage = async () => {
    try {
      const res = await http.get('setting/message')

      setCustomMsg(res.data.body)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleSendNotify = async message => {
    try {
      const payload = {
        ids: idSelect,
        customMsg: message,
      }
      const { data: result } = await withLoading(() => http.post('notification/push', payload))
      if (result.status === 'success') {
        showWithConfig({
          message: result.message,
          title: 'ສົ່ງຂໍ້ຄວາມສໍາ​ເລັດ',
          type: 'success',
        })
      } else {
        showWithConfig({
          message: result.message,
          title: 'ສົ່ງຂໍ້ຄວາມຜິດພາດ',
          type: 'error',
        })
      }
      setShowNotify(false)
    } catch (err) {
      console.log(err)
      setShowNotify(false)
      showWithMessage(err.message)
    }
  }

  const addUser = () => {
    setInitialValue({})
    setActionEdit(false)
    setShowModal(true)
  }

  const handleSubmit = async payload => {
    try {
      let result
      const data = {
        name: payload.name.trim(),
        password: payload.password.trim(),
        mobilePhone: payload.mobilePhone.trim(),
      }
      if (actionEdit) {
        result = await withLoading(() => http.put(`user/update/${payload._id}`, data))
      } else {
        result = await withLoading(() => http.post('user/create', data))
      }

      if (result.status === 'success') {
        showWithConfig({
          message: ' ​ຂໍ້ມູນບັນທຶກສໍາ​ເລັດແລ້ວ',
          title: 'ລາຍການລູກຄ້າ',
          type: 'success',
        })
        getListUser()
      } else {
        showWithConfig({
          message: ` ຂໍ້ມູນບັນທຶກບໍ່ສໍາ​ເລັດ ${result.message}`,
          title: 'ຂໍ້ມູນຜິດພາດ',
          type: 'error',
        })
      }
      setShowModal(false)
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleDelete = async selected => {
    try {
      const { status, message } = await http.post('user/delete', {
        ids: selected,
      })
      if (status !== 'success') {
        return showWithMessage(message)
      }
      showWithConfig({
        message: ' ​ຂໍ້ມູນທີ່ຖືກລົບສໍາ​ເລັດ',
        title: 'ລາຍການລູກຄ້າ',
        type: 'success',
      })
      return getListUser()
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const openConfirmDelete = selected => {
    showWithConfig({ message: 'ທ່ານແນ່ໃຈບໍ່ທີ່ຈະດຳເນີນການ ?', title: 'ຢືນຢັນ' }, () =>
      handleDelete(selected),
    )
  }

  const openConfirmSendNotify = selected => {
    setShowNotify(true)
    const user = rows.filter(item => selected.includes(item._id))
    setSelectUser(user)
    setIdSelent(selected)
  }

  const editProduct = payload => {
    const edit = {
      ...payload,
      weight: payload.weight || 0,
      volume: payload.volume || 0,
    }
    setInitialValue(edit)
    setActionEdit(true)
    setShowModal(true)
  }

  useEffect(() => {
    getListUser()
    getMessage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TableCustomer
        name="ລາຍຊື່ ລູກຄ້າ"
        rows={rows}
        total={total}
        headCells={headCells}
        getListProduct={getListUser}
        addUser={addUser}
        editProduct={editProduct}
        openConfirmDelete={openConfirmDelete}
        openConfirmSendNotify={openConfirmSendNotify}
        setSelected={setSelected}
        selected={selected}
      />
      <Loading />
      <Dialog />
      <ModalEdit
        show={showModal}
        handleClose={() => setShowModal(false)}
        initialValues={initialValue}
        actionEdit={actionEdit}
        handleSave={handleSubmit}
      />
      <ModalNotify
        show={showNotify}
        msg={customMsg}
        user={selectUser}
        handleSendNotify={handleSendNotify}
        handleClose={() => setShowNotify(false)}
      />
    </>
  )
}
