import React, { useState } from 'react'
import store from 'store'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core'
import { Edit, Delete } from '@material-ui/icons'
import EnhancedTableHead from './enhancedTableHead'
import EnhancedTableToolbar from './enhancedTableToolbar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  image: {
    width: 100,
    height: 100,
  },
}))

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)

export default props => {
  const userStore = store.get('user')
  const admin = userStore && userStore.role === 'admin'

  const { name, rows, total, headCells, getListMap, deleteMap, addProduct, editProduct } = props
  const classes = useStyles()
  const [sort, setSort] = useState({ sortBy: 'user_name', sortType: 'asc' })
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [keyword, setKeyword] = useState('')
  const [sum, setSum] = useState([])

  const handleRequestSort = (event, property) => {
    const isDesc = sort.sortBy === property && sort.sortType === 'desc'
    const payload = {
      sortBy: property,
      sortType: isDesc ? 'asc' : 'desc',
    }
    setSort(payload)
    getListMap(offset, rowsPerPage, payload, keyword)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id)
      const sumed = rows.map(n => n.cost)
      setSelected(newSelecteds)
      setSum(sumed)
      return
    }
    setSelected([])
    setSum([])
  }

  const handleChangePage = (event, newPage) => {
    setOffset(rowsPerPage * newPage)
    setPage(newPage)
    getListMap(rowsPerPage * newPage, rowsPerPage, sort, keyword)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    getListMap(offset, event.target.value, sort, keyword)
  }

  const handleSearch = value => {
    setKeyword(value)
    getListMap(0, rowsPerPage, sort, value)
    setPage(0)
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          name={name}
          numSelected={selected.length}
          total={sum}
          handleSearch={handleSearch}
          selected={selected}
          openConfirm={deleteMap}
          addProduct={addProduct}
        />
        <div>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={sort.sortType}
              orderBy={sort.sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <StyledTableRow hover tabIndex={index} key={row._id}>
                    <StyledTableCell
                      style={{ maxWidth: 10 }}
                      component="th"
                      scope="row"
                      padding="default"
                    >
                      {row.index}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <img className={classes.image} src={row.image} alt="" />
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.description}</StyledTableCell>
                    <StyledTableCell align="right">{row.address}</StyledTableCell>
                    <StyledTableCell align="right">{row.phone_number}</StyledTableCell>
                    <StyledTableCell align="right">{row.google_map}</StyledTableCell>
                    <StyledTableCell align="right">{row.warehouse_name}</StyledTableCell>
                    {admin && (
                      <StyledTableCell align="right">
                        <div style={{ display: 'grid' }}>
                          <Button
                            color="primary"
                            onClick={() => editProduct(row)}
                            startIcon={<Edit />}
                          >
                            ແກ້ໄຂ
                          </Button>
                          <Button
                            style={{ color: 'red' }}
                            color="secondary"
                            onClick={() => deleteMap(row._id)}
                            startIcon={<Delete />}
                          >
                            ລົບ
                          </Button>
                        </div>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                )
              })}
              {rows.length === 0 && <StyledTableRow style={{ height: 53 * emptyRows }} />}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="ແຖວຕໍ່ຫນ້າ"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
