import React from 'react'
import { FormControl, TextField } from '@material-ui/core'

const Input = ({ input: { input, meta }, label, ...n }) => (
  <FormControl margin="normal" required fullWidth>
    <TextField {...input} {...n} label={label} variant="outlined" />
    {meta?.touched && meta?.error && (
      <div style={{ color: '#dc3545', marginTop: 5 }}>{meta.error}</div>
    )}
  </FormControl>
)

export default Input
