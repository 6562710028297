import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isArray, map } from 'lodash'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(() => styles())

export default props => {
  const classes = useStyles()
  const { message, title = 'ບໍ່ຖືກຕ້ອງ', btnLabel, type = 'error' } = props
  const [options, upadteOptions] = React.useState({
    message,
    title,
    btnLabel,
    type,
  })

  function showWithMessage(msg, callback) {
    upadteOptions({
      ...options,
      message: msg,
      callback,
    })
  }

  function showWithConfig(configs, callback) {
    upadteOptions({
      ...options,
      ...configs,
      callback,
    })
  }

  const [open, setOpen] = React.useState(false)

  function handleClose() {
    setOpen(false)
  }

  React.useEffect(() => {
    const opened = options.message && options.message.length
    setOpen(opened)
  }, [options])

  return [
    () => (
      <Dialog
        open={Boolean(open)}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
      >
        <DialogTitle className={options.type === 'error' ? classes.error : classes.success}>
          {options.title}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {isArray(options.message) ? (
            map(options.message, (item, index) => (
              <DialogContentText key={index}>{item}</DialogContentText>
            ))
          ) : (
            <DialogContentText>{options.message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {options.btnLabel || 'ປິດ'}
          </Button>
          {options.callback && (
            <Button onClick={options.callback} color="primary" variant="contained" autoFocus>
              ຖືກຕ້ອງ
            </Button>
          )}
        </DialogActions>
      </Dialog>
    ),
    showWithMessage,
    showWithConfig,
  ]
}
