import React from 'react'
import clsx from 'clsx'
import store from 'store'
import { sum } from 'lodash'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Typography,
  Button,
  Tooltip,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { AddShoppingCart, AddBox, Delete, DateRange, Autorenew } from '@material-ui/icons'
import { formatMoney } from '../../service/helper'
import Search from '../search'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  buttonAdd: {
    minWidth: 130,
    color: 'white',
    marginRight: 5,
  },
  buttonGot: {
    minWidth: 150,
  },
  buttonDelete: {
    minWidth: 130,
    marginLeft: 5,
    backgroundColor: 'red',
  },
  textTotal: {
    marginRight: 20,
  },
  datePicker: {
    marginRight: 8,
    marginLeft: 8,
    marginTop: 0,
    marginBlock: 0,
  },
  btnSearch: {
    marginLeft: 24,
    marginTop: 12,
  },
  switch: {
    marginLeft: 4,
    marginTop: 18,
  },
}))

const EnhancedTableToolbar = ({
  numSelected,
  openConfirm,
  handleSearch,
  name,
  selected,
  total,
  bath,
  addProduct,
  openConfirmDelete,
  handleFilter,
  sumVolume,
  sumWeight,
}) => {
  const classes = useToolbarStyles()
  const userStore = store.get('user')
  const manager = userStore && userStore.role === 'manager'

  const [onRange, setOnRange] = React.useState(false)

  const [fromDate, setFromDate] = React.useState(null)
  const [toDate, setToDate] = React.useState(null)

  const handleClear = () => {
    handleFilter()
  }

  const sumTotal = value => {
    return sum(value)
  }

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} ລາຍການທີ່ເລືອກ
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {name}
          </Typography>
        )}
        {manager && (
          <Tooltip title="ເພີ່ມສິນຄ້າ">
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonAdd}
              onClick={addProduct}
              startIcon={<AddBox />}
            >
              ເພີ່ມສິນຄ້າ
            </Button>
          </Tooltip>
        )}
        {numSelected > 0 && (
          <>
            <Tooltip title="ຮັບເຄື່ອງແລ້ວ">
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonGot}
                onClick={() => openConfirm(selected)}
                startIcon={<AddShoppingCart />}
              >
                ຮັບເຄື່ອງແລ້ວ
              </Button>
            </Tooltip>
            <Tooltip title="ຮັບເຄື່ອງແລ້ວ">
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonDelete}
                onClick={() => openConfirmDelete(selected)}
                startIcon={<Delete />}
              >
                ລົບລາຍການ
              </Button>
            </Tooltip>
          </>
        )}
      </Toolbar>
      <Grid>
        {sumVolume || sumWeight ? (
          <Typography variant="h6" gutterBottom align="right" className={classes.textTotal}>
            ນ້ຳໜັກ: {sumWeight ? sumWeight.toFixed(2) : 0} kg ແລະ ບໍລິມາດ:{' '}
            {sumVolume ? sumVolume.toFixed(3) : 0} m<sup>3</sup>
          </Typography>
        ) : (
          <div />
        )}
        <Typography variant="h6" gutterBottom align="right" className={classes.textTotal}>
          ລາຄາລວມ {formatMoney(sumTotal(total))} ກີບ
          {bath && bath.length > 0 && sumTotal(bath) > 0 && `& ${formatMoney(sumTotal(bath))} ບາດ`}
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Search handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={12} md={9}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid>
              <FormControlLabel
                className={classes.switch}
                control={
                  <Switch
                    checked={onRange}
                    onChange={() => setOnRange(!onRange)}
                    color="primary"
                    name="range"
                    inputProps={{ 'aria-label': 'ໄລຍະເວລາ ວັນທີ່ເຄື່ອງເຂົ້າສາງ' }}
                  />
                }
                label="ໄລຍະເວລາ ວັນທີ່ເຄື່ອງເຂົ້າສາງ"
              />
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-from"
                label={onRange ? 'ເລີ່ມວັນທີ່' : 'ວັນທີ່ເຄື່ອງເຂົ້າສາງ'}
                format="dd/MM/yyyy"
                value={fromDate}
                onChange={date => setFromDate(date)}
                className={classes.datePicker}
                KeyboardButtonProps={{
                  'aria-label': onRange ? 'ເລີ່ມວັນທີ່' : 'ວັນທີ່ເຄື່ອງເຂົ້າສາງ',
                }}
              />
              {onRange && (
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-to"
                  label="ຮອດວັນທີ່"
                  format="dd/MM/yyyy"
                  value={toDate}
                  onChange={date => setToDate(date)}
                  className={classes.datePicker}
                  KeyboardButtonProps={{
                    'aria-label': 'ຮອດວັນທີ່',
                  }}
                />
              )}
              {(fromDate || toDate) && (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnSearch}
                  onClick={() => handleFilter(fromDate, toDate, onRange)}
                  startIcon={<DateRange />}
                >
                  ຄົ້ນຫາ
                </Button>
              )}

              <Button
                variant="contained"
                className={classes.btnSearch}
                onClick={() => handleClear()}
                startIcon={<Autorenew />}
              >
                ຍົກເລີກ
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </>
  )
}

export default EnhancedTableToolbar
