import store from 'store'
import axios from 'axios'
import configs from '../config'

const http = axios.create({
  baseURL: configs.baseUrl,
  timeout: 60000,
})

// Add a request interceptor
http.interceptors.request.use(
  config => {
    const request = config
    const token = store.get('token')

    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`,
      }
    }

    return config
  },
  error => {
    console.log('error', JSON.stringify(error))
    Promise.reject(error)
  },
)

// Add a response interceptor
http.interceptors.response.use(
  response => {
    // Return JSON data
    if (response.data) {
      return response.data
    }
    return response
  },
  error => {
    if (/401/.test(error)) {
      store.remove('token')
      store.remove('user')
      window.location.reload()
    }
    // Attempt to get the actual error returned from API
    const err = (error.response && error.response.data) || error

    return Promise.reject(err) // Propagate rejection back to caller
  },
)

export default http
