import Joi from 'joi'
import { reduce } from 'lodash'

const options = { abortEarly: false }

export default (schema) => values => Joi.validate(values, schema, options, err => {
	if (!err) {
		return {}
	}
	const generateErr = (accumulator, { message, path: [name] }) => {
		return {
			...accumulator,
			[name]: message
		}
	}
	const error = reduce(err.details, generateErr, {})
	return error
})