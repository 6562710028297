import React, { useState, useMemo } from 'react'
import { Button, Container, Card, CardContent } from '@material-ui/core'
import { Publish, CloudUpload } from '@material-ui/icons'
import axios from 'axios'
import store from 'store'
import queryString from 'query-string'
import http from '../../service/http'
import configs from '../../config'
import { useLoading, useDialog } from '../../components'

export default ({ classes }) => {
  const country = 'CN'
  const token = store.get('token')
  const userStore = store.get('user')
  const admin = userStore && userStore.role === 'admin'

  const [image, setImage] = useState({})
  const [preview, setPreview] = useState()
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({ title: 'ແກ້ໄຂທີ່ຢູ່ຈີນ' })

  const getPrice = async () => {
    try {
      const query = queryString.stringify({
        country,
      })
      const { data, status, message } = await withLoading(() =>
        http.get(`setting/calculate?${query}`),
      )
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        setPreview(data.image)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleSelectImage = e => {
    const img = e.target.files[0]
    if (img) {
      setPreview(URL.createObjectURL(img))
      setImage(img)
    }
  }

  const handleUpload = async () => {
    try {
      const query = queryString.stringify({
        country,
      })
      const fromData = new FormData()
      fromData.append('image', image, image.name)
      const result = await axios.post(`${configs.baseUrl}/upload/calculate?${query}`, fromData, {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      if (result.data.data !== 'success') {
        showWithConfig({ type: 'error', message: result.data.message, title: 'ຂໍ້ມູນຜິດພາດ' })
      } else {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
      }
    } catch (err) {
      console.log(err)
      showWithMessage(err.message)
    }
  }

  useMemo(() => {
    getPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <img src={preview} alt="" className={classes.image} />
          <div className={classes.buttonUpload}>
            <input
              color="primary"
              accept="image/*"
              type="file"
              onChange={handleSelectImage}
              id="calculate-china"
              style={{ display: 'none' }}
            />
            <label htmlFor="calculate-china">
              {admin && (
                <Button variant="contained" component="span" size="large" color="primary">
                  <span style={{ marginRight: 4 }}>ເລືອກຮູບ</span> <Publish />
                </Button>
              )}
            </label>
          </div>
        </CardContent>
        {image.name && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleUpload}
            className={classes.button}
          >
            <span style={{ marginRight: 4 }}>Upload</span> <CloudUpload />
          </Button>
        )}
      </Card>
      <Loading />
      <Dialog />
    </Container>
  )
}
