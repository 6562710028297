import React from 'react'
import { useStyles } from './styles'
import Warehouse from './configWarehouse'

export default () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Warehouse classes={classes} />
    </div>
  )
}
