import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  Button,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { formatMoney } from '../../service/helper'
import EnhancedTableHead from './enhancedTableHead'
import EnhancedTableToolbar from './enhancedTableToolbar'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  buttonEdit: {
    padding: 8,
  },
}))

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)

export default props => {
  const {
    name,
    rows,
    total,
    headCells,
    getListProduct,
    openConfirm,
    openConfirmDelete,
    addProduct,
    editProduct,
    selected,
    setSelected,
    setSum,
    sum,
    bath,
    setBath,
  } = props
  const classes = useStyles()
  const [sort, setSort] = useState({ sortBy: 'time', sortType: 'desc' })
  const [page, setPage] = useState(0)
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState({})
  const [selectOrder, setSelectOrder] = useState([])
  const [sumWeight, setSumWeight] = useState(0)
  const [sumVolume, setSumVolume] = useState(0)

  const handleRequestSort = (event, property) => {
    const isDesc = sort.sortBy === property && sort.sortType === 'desc'
    const payload = {
      sortBy: property,
      sortType: isDesc ? 'asc' : 'desc',
    }
    setSort(payload)
    getListProduct(offset, rowsPerPage, payload, keyword.filter)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id)
      const sumed = rows.map(n => n.cost)
      const listBath = rows.map(n => n.bath || n.width)
      const weight = rows.reduce((acc, obj) => acc + obj.weight, 0)
      const volume = rows.reduce((acc, obj) => acc + obj.volume, 0)
      setSumWeight(weight)
      setSumVolume(volume)
      setSelectOrder([...selectOrder, ...rows])
      setSelected(newSelecteds)
      setBath(listBath)
      setSum(sumed)
      return
    }
    setSumWeight(0)
    setSumVolume(0)
    setSelectOrder([])
    setSelected([])
    setBath([])
    setSum([])
  }

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row._id)
    let newSelected = []
    let newSumed = []
    let newBath = []
    let orders = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row._id)
      newSumed = newSumed.concat(sum, row.cost)
      newBath = newBath.concat(bath, row.bath || row.width)
      orders = orders.concat(selectOrder, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
      newSumed = newSumed.concat(sum.slice(1))
      newBath = newBath.concat(bath.slice(1))
      orders = orders.concat(selectOrder.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
      newSumed = newSumed.concat(sum.slice(0, -1))
      newBath = newBath.concat(bath.slice(0, -1))
      orders = orders.concat(selectOrder.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
      newSumed = newSumed.concat(sum.slice(0, selectedIndex), sum.slice(selectedIndex + 1))
      newBath = newBath.concat(bath.slice(0, selectedIndex), bath.slice(selectedIndex + 1))
      orders = orders.concat(
        selectOrder.slice(0, selectedIndex),
        selectOrder.slice(selectedIndex + 1),
      )
    }

    const weight = orders.reduce((acc, obj) => acc + obj.weight, 0)
    const volume = orders.reduce((acc, obj) => acc + obj.volume, 0)
    setSumWeight(weight)
    setSumVolume(volume)
    setSum(newSumed)
    setBath(newBath)
    setSelected(newSelected)
    setSelectOrder(orders)
  }

  const handleChangePage = (event, newPage) => {
    setOffset(rowsPerPage * newPage)
    setPage(newPage)
    getListProduct(rowsPerPage * newPage, rowsPerPage, sort, keyword, filter)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
    getListProduct(0, event.target.value, sort, keyword, filter)
  }

  const handleSearch = value => {
    setKeyword(value)
    getListProduct(0, rowsPerPage, sort, value, filter)
    setPage(0)
  }

  const handleFilter = (from, to, range) => {
    const payload = {
      from,
      to,
      range,
    }
    setFilter(payload)
    getListProduct(0, rowsPerPage, sort, keyword, payload, filter)
    setPage(0)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          name={name}
          numSelected={selected.length}
          total={sum}
          bath={bath}
          sumWeight={sumWeight}
          sumVolume={sumVolume}
          handleSearch={handleSearch}
          handleFilter={handleFilter}
          selected={selected}
          openConfirm={openConfirm}
          addProduct={addProduct}
          openConfirmDelete={openConfirmDelete}
        />
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200, 300, 500, 1000]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="ແຖວຕໍ່ຫນ້າ"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <div>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={sort.sortType}
              orderBy={sort.sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row._id)
                const labelId = `product-table-checkbox-${index}`

                return (
                  <StyledTableRow
                    hover
                    onClick={event => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ maxWidth: 10 }}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="default"
                    >
                      {row.index}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {moment(row.time)
                        .subtract(7, 'hours')
                        .format('LL')}
                    </StyledTableCell>
                    <StyledTableCell align="right">{row.user_tel}</StyledTableCell>
                    <StyledTableCell align="right">{row.user_name}</StyledTableCell>
                    <StyledTableCell align="right">{row.code}</StyledTableCell>
                    {row.type === 'cn' && (
                      <StyledTableCell align="right">
                        {row.weight ? `${formatMoney(row.weight, 2)} kg` : '0'}
                      </StyledTableCell>
                    )}
                    {row.type === 'cn' && (
                      <StyledTableCell align="right">
                        {row.volume ? `${formatMoney(row.volume, 3)} m3` : '0'}
                      </StyledTableCell>
                    )}
                    {row.type === 'th' && (
                      <StyledTableCell align="right" style={{ minWidth: 100 }}>
                        {row.bath || row.width
                          ? `${formatMoney(row.bath || row.width, 2)} ບາດ`
                          : '0'}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="right">{formatMoney(row.cost)} ກີບ</StyledTableCell>
                    <StyledTableCell align="right">
                      {row.warehouse_name ? row.warehouse_name : ''}
                    </StyledTableCell>
                    <StyledTableCell align="right" className={classes.buttonEdit}>
                      <Button onClick={() => editProduct(row)} startIcon={<Edit />}>
                        ແກ້ໄຂລູກຄ້າ
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
              {rows.length === 0 && <StyledTableRow style={{ height: 53 * emptyRows }} />}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200, 300, 500, 1000]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="ແຖວຕໍ່ຫນ້າ"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
