import React, { useState, useMemo } from 'react'
import { Button, Container, Card, Grid, CardContent, OutlinedInput } from '@material-ui/core'
import { Publish, Save, CloudUpload } from '@material-ui/icons'
import axios from 'axios'
import store from 'store'
import queryString from 'query-string'
import http from '../../service/http'
import configs from '../../config'
import { useLoading, useDialog } from '../../components'

export default ({ classes }) => {
  const country = 'CN'
  const token = store.get('token')
  const userStore = store.get('user')
  const admin = userStore && userStore.role === 'admin'

  const [image, setImage] = useState({})
  const [preview, setPreview] = useState()
  const [receiveName, setReceiveName] = useState('')
  const [receiveAddress, setReceiveAddress] = useState('')
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({ title: 'ແກ້ໄຂທີ່ຢູ່ຈີນ' })

  const getPrice = async () => {
    try {
      const query = queryString.stringify({
        country,
      })
      const { data, status, message } = await withLoading(() =>
        http.get(`setting/address-guild?${query}`),
      )
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        setReceiveName(data.receiveName || undefined)
        setReceiveAddress(data.receiveAddress)
        setPreview(data.image)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const handleSelectImage = e => {
    const img = e.target.files[0]
    if (img) {
      setPreview(URL.createObjectURL(img))
      setImage(img)
    }
  }

  const handleUpload = async () => {
    try {
      const query = queryString.stringify({
        country,
      })
      const fromData = new FormData()
      fromData.append('image', image, image.name)
      const result = await axios.post(
        `${configs.baseUrl}/upload/address-guild?${query}`,
        fromData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (result.data.data !== 'success') {
        showWithConfig({ type: 'error', message: result.data.message, title: 'ຂໍ້ມູນຜິດພາດ' })
      } else {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
      }
    } catch (err) {
      console.log(err)
      showWithMessage(err.message)
    }
  }

  const handleUpdate = async () => {
    try {
      const payload = {
        receiveAddress,
        receiveName,
        country,
      }
      const result = await withLoading(() => http.put('setting/address-guild', payload))

      if (result.status === 'success') {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
      } else {
        console.log(result.message)
        showWithMessage(result.message)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  useMemo(() => {
    getPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Grid className={classes.input}>
                <label>ທີ່ຢູ່</label>
                <OutlinedInput
                  fullWidth
                  multiline
                  rows="4"
                  value={receiveAddress}
                  onChange={event => setReceiveAddress(event.target.value)}
                />
              </Grid>
            </CardContent>
            {admin && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                className={classes.button}
              >
                ບັນທືກ <Save />
              </Button>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <img src={preview} alt="" className={classes.image} />
              <div className={classes.buttonUpload}>
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  onChange={handleSelectImage}
                  id="input-file-china"
                  style={{ display: 'none' }}
                />
                <label htmlFor="input-file-china">
                  {admin && (
                    <Button variant="contained" component="span" size="large" color="primary">
                      <span style={{ marginRight: 4 }}>ເລືອກຮູບ</span> <Publish />
                    </Button>
                  )}
                </label>
              </div>
            </CardContent>
            {image.name && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleUpload}
                className={classes.button}
              >
                <span style={{ marginRight: 4 }}>Upload</span> <CloudUpload />
              </Button>
            )}
          </Card>
        </Grid>
      </Grid>
      <Loading />
      <Dialog />
    </Container>
  )
}
