import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, InputBase, IconButton } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 270,
    maxWidth: 350,
    marginLeft: 6
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
})

export default function CustomizedInputBase({ handleSearch }) {
  const classes = useStyles()
  const [search, setSearch] = useState('')

  const keyPress = (event) => {
    if (event.keyCode === 13){
      handleSearch(event.target.value)
    }
  }

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="ຄົ້ນຫາ"
        value={search}
        onChange={event => setSearch(event.target.value)}
        inputProps={{ 'aria-label': 'ຄົ້ນຫາ' }}
        onKeyDown={keyPress}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={() => handleSearch(search)}
      >
        <Search />
      </IconButton>
    </Paper>
  )
}
