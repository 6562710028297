import React, { useState, useMemo } from 'react'
import {
  Button,
  Container,
  Card,
  Grid,
  CardContent,
  OutlinedInput
} from '@material-ui/core'
import http from '../../service/http'
import { useLoading, useDialog } from '../../components'

export default ({ classes }) => {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [Loading, withLoading] = useLoading(false)
  const [Dialog, showWithMessage, showWithConfig] = useDialog({ title: 'ແກ້ໄຂໍ້ຄວາມແຈ້ງເຕືອນ' })

  const getPrice = async () => {
    try {
      const { data, status, message } = await withLoading(() => http.get('setting/message'))
      if (status !== 'success') {
        showWithMessage(message)
      } else {
        setTitle(data.title)
        setBody(data.body)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  const onSubmit = async () => {
    try {
      const payload = {
        title,
        body
      }
      const result = await withLoading(() => http.put('setting/message', payload))

      if (result.status === 'success') {
        showWithConfig({ type: 'success', message: 'ບັນທຶກສຳເລັດ' })
      } else {
        console.log(result.message)
        showWithMessage(result.message)
      }
    } catch (error) {
      console.log(error)
      showWithMessage(error.message)
    }
  }

  useMemo(() => {
    getPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <Card>
        <CardContent>
          <Grid>
            <label>ຫົວຂໍ້</label>
            <OutlinedInput
              fullWidth
              value={title}
              onChange={event => setTitle(event.target.value)}
            />
          </Grid>
          <Grid className={classes.input}>
            <label>ເນື້ອໃນ</label>
            <OutlinedInput
              fullWidth
              multiline
              rows="4"
              value={body}
              onChange={event => setBody(event.target.value)}
            />
          </Grid>
        </CardContent>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.button}
        >
          ບັນທືກ
        </Button>
      </Card>
      <Loading />
      <Dialog />
    </Container>
  )
}
