import React from 'react'
import clsx from 'clsx'
import store from 'store'
import { lighten, makeStyles } from '@material-ui/core/styles'
import {
  Toolbar,
  Typography,
  Button,
  Tooltip,
  Grid,
} from '@material-ui/core'
import { AddBox, Delete, Email } from '@material-ui/icons'
import Search from '../search'

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  buttonAdd: {
    minWidth: 130,
    color: 'white',
    marginRight: 5,
  },
  buttonGot: {
    minWidth: 150,
  },
  buttonDelete: {
    minWidth: 130,
    marginLeft: 5,
    backgroundColor: 'red',
  },
  textTotal: {
    marginRight: 20,
  },
  datePicker: {
    marginRight: 8,
    marginLeft: 8,
    marginTop: 0,
    marginBlock: 0,
  },
  btnSearch: {
    marginLeft: 24,
    marginTop: 12,
  },
  switch: {
    marginLeft: 4,
    marginTop: 18,
  },
}))

const EnhancedTableToolbar = ({
  numSelected,
  handleSearch,
  name,
  selected,
  openConfirmSendNotify,
  openConfirmDelete,
  addUser,
}) => {
  const classes = useToolbarStyles()
  const userStore = store.get('user')
  const admin = userStore && userStore.role === 'admin'

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} ລາຍການທີ່ເລືອກ
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {name}
          </Typography>
        )}
        {admin && (
          <Tooltip title="ເພີ່ມລູກຄ້າ">
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonAdd}
              onClick={addUser}
              startIcon={<AddBox />}
            >
              ເພີ່ມລູກຄ້າ
            </Button>
          </Tooltip>
        )}
        {numSelected > 0 && (
          <>
            <Tooltip title="ສົ່ງແຈ້ງເຕືອນ">
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonGot}
                onClick={() => openConfirmSendNotify(selected)}
                startIcon={<Email />}
              >
                ສົ່ງແຈ້ງເຕືອນ
              </Button>
            </Tooltip>

            <Tooltip title="ລົບລູກຄ້າ">
              <Button
                variant="contained"
                className={classes.buttonDelete}
                onClick={() => openConfirmDelete(selected)}
                startIcon={<Delete />}
              >
                ລົບລູກຄ້າ
              </Button>
            </Tooltip>
          </>
        )}
      </Toolbar>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Search handleSearch={handleSearch} />
        </Grid>
      </Grid>
    </>
  )
}

export default EnhancedTableToolbar
