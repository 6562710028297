export default [
  { id: 'index', label: 'ລຳດັບ' },
  { id: 'createdAt', label: 'ວັນທີ', minWidth: 170 },
  { id: 'user_tel', label: 'ເບີໂທ', minWidth: 170 },
  {
    id: 'user_name',
    label: 'ຊື່',
    minWidth: 170,
  },
  {
    id: 'code',
    label: 'ລະຫັດ',
    align: 'right',
    minWidth: 170,
  },
  {
    id: 'bath',
    label: 'ລາຄາ (bath)',
    align: 'right',
    minWidth: 100,
    format: value => value.toLocaleString(),
  },
  {
    id: 'cost',
    label: 'ລາຄາ',
    align: 'right',
    minWidth: 100,
    format: value => value.toLocaleString(),
  },
  {
    id: 'warehouse_name',
    label: 'ສາງ',
    align: 'right',
  },
]
