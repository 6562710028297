/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react'
import Joi from 'joi'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form-hooks'
import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'
import { Close, Publish } from '@material-ui/icons'
import validate from '../../../service/form/validation'
import formCreateInputs from '../../../service/form/create'
import { TextInput } from '../../../components'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  form: {
    padding: 20,
  },
  image: {
    width: '100%',
    border: '1px solid #b9b9b9',
    marginBottom: 8,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ show, handleClose, initialValues, handleUpload, actionEdit }) {
  const classes = useStyles()
  const [preview, setPreview] = React.useState()
  const [image, setImage] = React.useState({})

  const schema = Joi.object().keys({
    description: Joi.string().required(),
    phone_number: Joi.string().required(),
    google_map: Joi.string().required(),
    address: Joi.string().required(),
    warehouse_name: Joi.string().required(),
  })

  const onSubmit = payload => {
    // handleUpload(payload)
  }

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues,
    validate: validate(schema),
  })

  const [description, phone_number, google_map, address, warehouse_name] = formCreateInputs(
    ['description', 'phone_number', 'google_map', 'address', 'warehouse_name'],
    form,
  )

  const handleProcess = () => {
    const descriptionValue = description.input.value
    const phoneNumberValue = phone_number.input.value
    const googleMapValue = google_map.input.value
    const addressValue = address.input.value
    const warehouseName = warehouse_name.input.value
    if (
      descriptionValue !== initialValues.description ||
      phoneNumberValue !== initialValues.phone_number ||
      googleMapValue !== initialValues.google_map ||
      addressValue !== initialValues.address ||
      warehouseName !== initialValues.warehouse_name ||
      image.name
    ) {
      const payload = {
        _id: initialValues._id,
        description: descriptionValue,
        phone_number: phoneNumberValue,
        google_map: googleMapValue,
        address: addressValue,
        image,
        warehouse_name: warehouseName,
      }
      handleUpload(payload)
    }
    return false
  }

  const validateValue = () => {
    const descriptionValue = description.input.value
    const phoneNumberValue = phone_number.input.value
    const googleMapValue = google_map.input.value
    const addressValue = address.input.value
    const warehouseName = warehouse_name.input.value
    if (
      !descriptionValue ||
      !phoneNumberValue ||
      !googleMapValue ||
      !addressValue ||
      !warehouseName
    ) {
      return true
    }
    if (
      descriptionValue !== initialValues.description ||
      phoneNumberValue !== initialValues.phone_number ||
      googleMapValue !== initialValues.google_map ||
      addressValue !== initialValues.address ||
      warehouseName !== initialValues.warehouse_name ||
      image.name
    ) {
      return false
    }
    return true
  }

  const handleSelectImage = e => {
    const img = e.target.files[0]
    if (img) {
      setPreview(URL.createObjectURL(img))
      setImage(img)
    }
  }

  return (
    <div>
      <Dialog open={show} onClose={handleClose} TransitionComponent={Transition}>
        <form onSubmit={handleSubmit}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {actionEdit ? 'ແກ້ໄຂສິນຄ້າ' : 'ເພີ່ມສິນຄ້າ'}
              </Typography>
              {actionEdit ? (
                <Button
                  autoFocus
                  type="submit"
                  color="inherit"
                  disabled={validateValue()}
                  onClick={handleProcess}
                >
                  ບັນທຶກ
                </Button>
              ) : (
                image.name && (
                  <Button autoFocus type="submit" color="inherit" onClick={handleProcess}>
                    ເພີ່ມ
                  </Button>
                )
              )}
            </Toolbar>
          </AppBar>
          <div className={classes.form}>
            <img
              src={preview || (initialValues && initialValues.image)}
              alt=""
              className={classes.image}
            />
            <div className={classes.buttonUpload}>
              <input
                color="primary"
                accept="image/*"
                type="file"
                onChange={handleSelectImage}
                id="input-address-lao"
                style={{ display: 'none' }}
              />
              <label htmlFor="input-address-lao">
                <Button variant="contained" component="span" size="large" color="primary">
                  <span style={{ marginRight: 4 }}>ເລືອກຮູບ</span> <Publish />
                </Button>
              </label>
            </div>
            <TextInput input={description} label="ອະທິບາຍຮູບ" />
            <TextInput input={address} label="ທີ່ຢູ່" />
            <TextInput input={phone_number} label="ເບີໂທຕິດຕໍ່" />
            <TextInput input={google_map} label="Google map Link" />
            <TextInput input={warehouse_name} label="ສາງ" />
          </div>
        </form>
      </Dialog>
    </div>
  )
}
